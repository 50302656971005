import React from 'react';
import { graphql } from 'gatsby';
import ApartmentList from '../components/ApartmentList';
import SEO from '../components/seo';

export default function ApartmentsPage(props) {
  const data = props.data;
  const apartments = data.apartments.nodes

  return (
    <div className="layout-container">
      <SEO title="Apartments" />
      <h2 className="margin-bottom margin-top h2-bg">Our Available Apartments</h2>
      <ApartmentList 
        apartments={apartments}
        showFullName={props.location.pathname.includes("apartments")} 
      />
    </div>
  )
}

export const query = graphql`
  query ApartmentQuery {
    apartments: allSanityApartment(sort: {order: ASC, fields: sortOrder}) {
      nodes {
        available
        deposit
        highprice
        id
        mainprice
        name
        sqft
        bathrooms
        sortOrder
        floorplan {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
            fixed(width: 400) {
              ...GatsbySanityImageFixed
            }
          }
        }
        images {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`


